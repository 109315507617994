<template>
  <div>
    <Toast position="top-right" />
    <div class="p-grid crud-demo">
      <FileViewer
        v-if="null !== fileViewer.url"
        :url-link="fileViewer.url"
        :original-url="fileViewer.originalUrl"
        :title="fileViewer.title"
        v-on:close="handleOnClose"
      />
      <div class="p-col-12">
        <div class="card">
          <!--:filters="filters"-->
          <DataTable
            ref="dt"
            :value="listData"
            data-key="id"
            responsiveLayout="scroll"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{totalRecords} inscriptions"
          >
            <template #header>
              <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between p-ai-start p-ai-md-center">
                <h5 class="p-mb-2 p-m-md-0">Gestion des inscriptions</h5>
              </div>
              <br />
              <div style="float: right">
                <!--<h6 v-if="errorOnSearch" style="color: red">Aucune entreprise n'a été trouvée.</h6>
                <h6 v-if="errorOnSearchSize" style="color: red">Le champ de recherche est vide.</h6>-->
              </div>
              <div class="filters">
                <div class="filter__item">
                  <label class="filter__label" for="">Statut</label>
                  <Dropdown
                    id="state"
                    v-model="filterValues.status"
                    :options="dropdownValues"
                    optionLabel="name"
                  ></Dropdown>
                </div>
                <div class="filter__item">
                  <label for="entreprise-id" class="filter__label">Entreprise</label>
                  <InputText v-model="filterValues.entrepriseName" placeholder="Rechercher une entreprise" />
                </div>
                <div class="filter__item">
                  <label class="filter__label" for="profile-entreprise-id">Profil d'entreprise</label>
                  <Dropdown
                    id="profile-entreprise-id"
                    v-model="filterValues.companyProfile"
                    :options="tarifsAsArray"
                    optionLabel="adherant"
                  ></Dropdown>
                </div>
                <div class="filter__item">
                  <label class="filter__label" for="profile-entreprise-id">Domaines métiers</label>
                  <MultiSelect
                    id="domaine-metier-id"
                    v-model="filterValues.domaineMetierIds"
                    :options="domainesMetiers"
                    :filter="true"
                    :style="'max-width: 400px'"
                    optionValue="id"
                    optionLabel="libelle"
                  />
                </div>
                <div class="filter--ml">
                  <div class="filter__item">
                    <label class="filter__label" for=""></label>
                    <Button
                      v-tooltip.top="'Exporter'"
                      icon="pi pi-download"
                      iconPos="right"
                      @click="exportData($event)"
                    />
                  </div>
                  <div class="filter__item">
                    <label class="filter__label" for=""></label>
                    <Button
                      class="p-button-help"
                      v-tooltip.top="'Effacer les filtres'"
                      icon="pi pi-filter-slash"
                      @click="resetFilter()"
                    />
                  </div>
                </div>
              </div>
            </template>
            <!--<div v-if="errorFile" style="color: red">Ce fichier est corrompu.</div>-->
            <Column field="statut" header="Statut" :sortable="true">
              <template #body="slotProps"
                >&nbsp;&nbsp;
                <span :class="'product-badge ' + getStatusClassName(slotProps.data)">{{
                  subscriptionStatusMapping[slotProps.data.statut]
                }}</span>
              </template>
            </Column>
            <Column field="user.firststName" header="Point focal" :sortable="false" bodyStyle="text-indent:1em">
              <template #body="slotProps"
                ><span v-for="user in slotProps.data.details.users" :key="user.id"
                  >{{ user.lastName }} {{ user.firstName }}</span
                ></template
              >
            </Column>
            <Column field="details.name" header="Nom de l'entreprise" :sortable="true" bodyStyle="text-indent:1em">
              <template #body="slotProps"
                ><b @click="onToggleDetails(slotProps.data)">{{ slotProps.data.details?.name }}</b></template
              >
            </Column>
            <Column field="details.email" header="E-mail" :sortable="true" bodyStyle="text-indent:1em">
              <template #body="slotProps">{{ slotProps.data.details.email }}</template>
            </Column>
            <Column field="price" header="Document fiscal" :sortable="false" bodyStyle="text-indent:1em">
              <template #body="slotProps">
                <preview-file-btn
                  :title="'Document fiscal - Numéro fiscal : ' + slotProps.data.details.taxIdNumber"
                  :url="slotProps.data.details.taxIdNumberUrl"
                  :btnLabel="'Visualiser'"
                  @triggered="handleShowPreview"
                />
              </template>
            </Column>

            <Column field="price" header="Statut de l'entreprise" :sortable="false" bodyStyle="text-indent:1em">
              <template #body="slotProps">
                <preview-file-btn
                  :title="'Statut de l\'entreprise'"
                  :url="slotProps.data.details.legalStatusUrl"
                  :btnLabel="'Visualiser'"
                  @triggered="handleShowPreview"
                />
              </template>
            </Column>

            <Column field="explore" header="Registre de commerce" :sortable="false" bodyStyle="text-indent:1em">
              <template #body="slotProps">
                <preview-file-btn
                  :title="'Registre de commerce - Numéro régistre : ' + slotProps.data.details.tradeRegister"
                  :url="slotProps.data.details.tradeRegisterUrl"
                  :btnLabel="'Visualiser'"
                  @triggered="handleShowPreview"
                />
              </template>
            </Column>

            <Column field="action" header="Actions" style="width: 13em" :sortable="false" bodyStyle="text-indent:1em">
              <template #body="slotProps">
                <span class="p-column-title">Actions</span>
                <Button
                  icon="pi pi-eye"
                  class="p-button-outlined p-button-rounded p-button-submit"
                  v-tooltip.top="'Voir les détails'"
                  @click="onToggleDetails(slotProps.data)"
                />&nbsp;
                <Button
                  v-show="isWaitingSubscriptionValidate(slotProps.data)"
                  icon="pi pi-thumbs-up"
                  v-tooltip.top="'Valider'"
                  class="p-button-outlined p-button-rounded p-button-success"
                  @click="handleOnValidate(slotProps.data)"
                />&nbsp;
                <Button
                  v-show="isWaitingSubscriptionValidate(slotProps.data)"
                  icon="pi pi-thumbs-down"
                  v-tooltip.top="'Rejeter'"
                  class="p-button-outlined p-button-rounded p-button-warning"
                  @click="handleOnReject(slotProps.data)"
                />&nbsp;
                <Button
                  icon="pi pi-id-card"
                  v-tooltip.top="'Changer profil'"
                  class="p-button-outlined p-button-rounded p-button-danger"
                  @click="onChangeProfile(slotProps.data)"
                />
              </template>
            </Column>
          </DataTable>

          <Paginator :rows="25" :totalRecords="response?.meta.total" @page="onPage($event)"></Paginator>

          <!--Dialog v-model:visible="displayDialog" :style="{ width: '600px' }" header :modal="true" class="p-fluid">
            <div>
              <img v-auth-image="previewImage" />
            </div>
          </Dialog-->

          <Dialog
            v-model:visible="dialogStates.ValidationView"
            :style="{ width: '600px' }"
            header="Êtes-vous sûr de vouloir confirmer l'inscription ?"
            :modal="true"
            class="p-fluid"
          >
            <template #footer>
              <Button
                label="NON"
                class="p-button p-component p-button-outlined p-button-danger"
                @click="handleHideDialogs"
              />
              <Button
                :loading="loadings[1]"
                label="OUI"
                class="p-button p-component p-button-outlined p-button-success"
                @click="handleOnConfirmValidation"
              />
            </template>
          </Dialog>

          <Dialog
            v-model:visible="dialogStates.RejectionView"
            :style="{ width: '450px' }"
            header="Motif du rejet d'inscription"
            :modal="true"
            class="p-fluid"
          >
            <div class="p-field">
              <label for="reject-message-id">Objet(s) du rejet</label>
              <Textarea
                :class="{ 'p-invalid': !payload.rejectSubscription.message && submitted }"
                v-model="payload.rejectSubscription.message"
                id="reject-message-id"
                required="true"
                rows="10"
                cols="25"
              />
              <small style="color: red" v-show="!payload.rejectSubscription.message && submitted">
                Veuillez renseigner le motif du rejet.
              </small>
            </div>
            <template #footer>
              <Button
                label="Annuler"
                icon="pi pi-times"
                class="p-button p-component p-button-outlined p-button-danger"
                @click="handleHideDialogs"
              />
              <Button
                :loading="loadings[2]"
                label="Rejeter"
                icon="pi pi-check"
                class="p-button p-component p-button-outlined p-button-success"
                @click="handleOnConfirmRejection"
              />
            </template>
          </Dialog>

          <Dialog
            v-model:visible="dialogStates.ChangeProfileView"
            :style="{ width: '450px' }"
            :header="`Changement du profil de ${detail?.entreprise?.name}`"
            :modal="true"
            class="p-fluid"
          >
            <div class="p-field">
              <label for="description">Profil</label>
              <Dropdown
                id="new-profile-entreprise-id"
                v-model="newProfileItem.rangSouscriptionEnCours"
                :options="tarifsAsArray"
                optionLabel="name"
              />
            </div>
            <div class="p-field">
              <label for="description">Montant</label>
              <InputText :disabled="true" type="text" v-model="newProfileItem.montantSouscriptionEnCours" />
            </div>
            <template #footer>
              <Button
                label="Annuler"
                icon="pi pi-times"
                class="p-button p-component p-button-outlined p-button-danger"
                @click="hideDialog"
              />
              <Button
                :loading="loading"
                label="Enregister les modifications"
                icon="pi pi-check"
                class="p-button p-component p-button-outlined p-button-success"
                @click="setNewProfile"
              />
            </template>
          </Dialog>

          <Sidebar class="p-sidebar-md" v-model:visible="dialogStates.DetailsView" position="right">
            <h4>Détails de l'entreprise {{ selectedSubscription?.details.name }}</h4>
            <br />
            <company-detail-list :subscription="selectedSubscription" />
            <br />
            <div v-show="isWaitingSubscriptionValidate(selectedSubscription)">
              <Button
                icon="pi pi-thumbs-down"
                label="Rejeter"
                class="p-button-outlined p-button-rounded p-button-danger"
                @click="handleOnReject(selectedSubscription)"
                :style="{ color: '#FBC02D' }"
              />
              &nbsp;&nbsp;
              <Button
                class="p-button-outlined p-button-rounded p-button-success"
                icon="pi pi-thumbs-up"
                label="Valider"
                @click="handleOnValidate(selectedSubscription)"
              />
            </div>
          </Sidebar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileViewer from '@/components/FileViewer';
import PreviewFileBtn from '@/components/preview-file-btn';
import { BackendUrls } from '@/services/urlsConfigs';
import LoggedUserMixin from '@/mixins/logged-user';
import { SubscriptionStatusAliasMixin } from '@/mixins/validation-status';
import CompanyDetailList from '@/components/company-detail-list';
import EnumFormatMixin from '@/mixins/enum-format';
import DocumentManagerMixin from '@/mixins/document-manager';
import http from '../services/http.service';
import Http from '../services/http-v2.service';
import moment from 'moment';
import XLSX from 'xlsx';
import { mapGetters } from 'vuex';

export default {
  mixins: [EnumFormatMixin, DocumentManagerMixin, LoggedUserMixin, SubscriptionStatusAliasMixin],
  components: {
    CompanyDetailList,
    FileViewer,
    PreviewFileBtn,
  },
  data: () => ({
    dialogStates: {
      DetailsView: false,
      ValidationView: false,
      RejectionView: false,
      ChangeProfileView: false,
    },
    listData: [],
    response: {
      data: [],
      meta: {},
    },
    selectedSubscription: null,
    dropdownValues: [
      { name: 'En attente de soumission', id: SubscriptionStatusAliasMixin.SUBMISSION_PENDING },
      { name: 'En cours', id: SubscriptionStatusAliasMixin.PENDING },
      { name: 'Validé', id: SubscriptionStatusAliasMixin.VALIDATED },
      { name: 'Rejeté', id: SubscriptionStatusAliasMixin.REJECTED },
      { name: 'Période de grâce', id: 'TRIAL' },
      { name: 'Tous', id: SubscriptionStatusAliasMixin.ALL },
    ],
    filterValues: {
      status: { name: 'En cours', id: SubscriptionStatusAliasMixin.PENDING },
      entrepriseName: null,
      domaineMetierIds: [],
      companyProfile: null,
      email: null,
    },
    tarifsAsArray: [],
    tarifs: [],
    domainesMetiers: [],
    newProfileItem: {
      rangSouscriptionEnCours: null,
      montantSouscriptionEnCours: null,
    },
    loadings: [false, false, false, false, false],
    submitted: false,
    payload: {
      rejectSubscription: { message: '' },
    },
  }),
  mounted() {
    if (localStorage.getItem('token')) {
      this.fetchDomainesMetiers();
      this.fetchTarifs();
      this.fetchSubscriptions('status=EN_COURS&sort=createdAt,asc');
    } else {
      this.$router.push('/login');
    }
  },
  computed: {
    ...mapGetters({
      isRevokeDialogsOpened: 'dialog/getRevokeDialogStatus',
    }),
  },
  watch: {
    filterValues: {
      handler: function (value) {
        this.refreshPage(value);
      },
      deep: true,
    },
    newProfileItem: {
      handler: function (event) {
        const rankId = event.rangSouscriptionEnCours?.id;
        this.newProfileItem.montantSouscriptionEnCours = this.tarifs[rankId].montant_xof;
      },
      deep: true,
    },
  },
  methods: {
    getStatusClassName(subscription) {
      switch (subscription.statut) {
        case 'EN_COURS':
          return 'status-lowstock';
        case 'APPROUVE':
          return 'status-instock';
        case 'REJETE':
          return 'status-outofstock';
        default:
          return '';
      }
    },

    getParamsUrl(value) {
      console.log({ value });
      const keyMap = {};
      if (value.status.id) keyMap.status = value.status.id;
      if (value.entrepriseName) keyMap.enterpriseName = value.entrepriseName;
      if (value.companyProfile?.rang) keyMap.rank = value.companyProfile.rang;
      if (value.domaineMetierIds.length > 0) keyMap.domainMetierIds = value.domaineMetierIds.map((id) => id).join(',');

      return Object.keys(keyMap).reduce((url, key) => `${url}${key}=${keyMap[key]}&`, '');
    },

    // getStatus(subscription) {},

    onToggleDetails(subscription) {
      this.selectedSubscription = subscription;
      this.dialogStates.DetailsView = !this.dialogStates.DetailsView;
    },

    refreshPage(value) {
      const params = this.getParamsUrl(value);
      this.fetchSubscriptions(params);
    },

    fetchSubscriptions(parameters) {
      return Http.get(BackendUrls.GetListSubscriptions(parameters))
        .then(this.handleFetchSubscriptionSucceed)
        .catch(this.handleFetchSubscriptionFailed);
    },

    handleFetchSubscriptionSucceed(response) {
      this.listData = response.data.data;
      this.response.data = response.data.data;
      this.response.meta = response.data.meta;
      return response;
    },

    handleFetchSubscriptionFailed() {},

    fetchTarifs() {
      http({
        method: 'get',
        url: '/tarifs',
      }).then((response) => {
        this.tarifsAsArray = response.data;
        this.tarifsAsArray.push({ id: null, adherant: 'Aucun', rang: null });
        this.tarifs = this.tarifsAsArray.reduce((h, tarif) => ({ ...h, [tarif.rang]: tarif }), {});
      });
    },

    fetchDomainesMetiers() {
      return http({
        method: 'get',
        url: '/domaines-metiers?limit=5000',
      }).then((response) => {
        this.domainesMetiers = response.data.data;
      });
    },

    exportData(event) {
      event.preventDefault();
      let filename = `${moment(new Date()).format('DDMMYYYYHHmmss')}-subscriptions-reports.xlsx`;
      let data = [];
      http.get(BackendUrls.GetListPayments()).then((response) => {
        data = data.concat(
          response.data.map((row) => {
            const entreprise = row.enterprise;
            return {
              STATUS: row.statut,
              ENTREPRISE: entreprise.name,
              POINT_FOCAL: entreprise.users.map((u) => `${u.firstName} ${u.lastName},${u.mobile},${u.phone}`).join('|'),
              EMAIL: entreprise.email,
              ENTREPRISE_TEL_1: entreprise.mobile,
              ENTREPRISE_TEL_2: entreprise.phone,
              POINT_FOCAL_TEL_1: entreprise.mobile,
              POINT_FOCAL_TEL_2: entreprise.phone,
              ADRESSE: entreprise.address,
              SITE_WEB: entreprise.www,
              PAYS: entreprise.name,
              PROFIL: this.tarifsAsArray.find((p) => p.rang === entreprise.rank)?.adherant,
              FORM_JURIDIQUE: entreprise.legalForm?.libelle,
              ID_FISCAL: entreprise.taxIdNumber,
              RC: entreprise.tradeRegister,
              DATE_CREATION: moment(entreprise.companyCreatedAt).format('DD/MM/yyyy'),
            };
          })
        );
        var ws = XLSX.utils.json_to_sheet(data);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Subscriptions');
        XLSX.writeFile(wb, filename);
      });
    },

    resetFilter() {
      this.filterValues.entrepriseName = null;
      this.filterValues.domaineMetierIds = [];
      this.filterValues.companyProfile = {};
      this.filterValues.email = null;
      this.filterValues.status = { name: 'En cours', id: 'EN_COURS' };
    },

    handleOnReject(subscription) {
      this.selectedSubscription = subscription;
      this.dialogStates.RejectionView = !this.dialogStates.RejectionView;
    },

    handleOnValidate(subscription) {
      this.selectedSubscription = subscription;
      this.dialogStates.ValidationView = !this.dialogStates.ValidationView;
    },

    handleOnConfirmRejection() {
      this.submitted = true;
      const subscription = this.selectedSubscription;
      Http.post(BackendUrls.PostRejectSubscription(subscription.details.id, subscription.id), {
        message: this.payload.rejectSubscription.message,
      })
        .then(this.handleRejectionSucceed)
        .catch(this.handleRejectionFailed);
    },

    handleRejectionSucceed() {
      //TODO VUEX.
      this.refreshPage(this.filterValues);
      this.handleHideDialogs();
      this.$toast.add({
        severity: 'success',
        summary: 'Succès',
        detail: "L'abonnement a bien été rejeté.",
        life: 3000,
      });
    },

    handleRejectionFailed() {
      this.$toast.add({
        severity: 'error',
        summary: 'Erreur',
        detail: "Echec du rejet de l'abonnement.",
        life: 3000,
      });
    },

    handleOnConfirmValidation() {
      const subscription = this.selectedSubscription;
      Http.post(BackendUrls.PostValidateSubscription(subscription.details.id, subscription.id), {
        state: 'CONTRACTOR',
      })
        .then(this.handleValidationSucceed)
        .catch(this.handleValidationFailed);
    },

    handleValidationSucceed() {
      this.refreshPage(this.filterValues);
      this.handleHideDialogs();
      this.$toast.add({
        severity: 'success',
        summary: 'Succès',
        detail: "L'abonnement a bien été validé.",
        life: 3000,
      });
    },

    handleValidationFailed() {
      this.$toast.add({
        severity: 'error',
        summary: 'Erreur',
        detail: "Echec de la validation de l'abonnement.",
        life: 3000,
      });
    },

    handleHideDialogs() {
      this.dialogStates.ChangeProfileView = false;
      this.dialogStates.ValidationView = false;
      this.dialogStates.RejectionView = false;
      this.dialogStates.DetailsView = false;
    },

    onChangeProfile(subscription) {
      const payload = {
        entrepriseId: subscription.details.id,
        rangSouscriptionEnCours: this.newProfileItem.rangSouscriptionEnCours,
        montantSouscriptionEnCours: 0,
      };
      http
        .post('/enterprises/setClassification', payload, this.config)
        .then(() => {
          this.hideDialog();
          this.$toast.add({
            severity: 'success',
            summary: 'Accomplie',
            detail: "Le profil de l'entreprise a bien été mis à jour.",
            life: 7000,
          });
        })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Echec',
            detail: 'Une erreur est survenue lors du changement de profile. Veuillez contacter votre administrateur.',
            life: 7000,
          });
        });
    },

    onPage(event) {
      const urlParams = `${this.getParamsUrl(this.filterValues)}page=${event.page}&sort=createdAt,asc`;
      this.fetchSubscriptions(urlParams);
    },
  },
};
</script>

<style scoped lang="scss">
.filters {
  display: flex;
}
.filter__item {
  display: inline-grid;
  margin-right: 5px;
}
.filter__label {
  margin: 6px 1px;
  font-weight: 500;
}

.filter--ml {
  margin-left: auto;
}

.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-instock {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-lowstock {
    background: #feedaf;
    color: #8a5340;
  }
}
</style>
